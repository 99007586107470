import { throttle } from 'lodash';
import { hamburgerInAcMenuOpen, hamburgerOpen, scrollHeaderColorChange } from './modules/headerConfig';
import { modalDeliveryToggle } from './modules/modalConfig';
import { smooth } from './modules/smooth';
import { accordion } from './modules/accordion';

smooth();
scrollHeaderColorChange();
hamburgerOpen();
hamburgerInAcMenuOpen();
modalDeliveryToggle();
accordion();

const fps = 60;
window.addEventListener(
  'scroll',
  throttle(() => scrollHeaderColorChange(), 10 / fps)
);

window.addEventListener('scroll', () => {
  scrollHeaderColorChange();
});
