import SmoothScroll from 'smooth-scroll';

export function smooth() {
  const scroll = new SmoothScroll('a[href*="#"]', {
    header: '#header',
    speed: 300,
    speedAsDuration: true,
    updateURL: false,
  });
}
