/** デリバリーメニュー */
export function modalDeliveryToggle() {
  const bodyEle = document.querySelector('body');
  const modalDeliveryTrigger = document.querySelector('.js-modalDeliveryTrigger');
  const modalDeliveryWindow = document.querySelector('.js-modalDeliveryWindow');
  const modalDeliveryClose = document.querySelector('.js-modalDelveryClose');

  if (document.querySelector('.js-modalDeliveryTrigger')) {
    modalDeliveryTrigger.addEventListener('click', () => {
      bodyEle.classList.add('is-fixed');
      modalDeliveryWindow.classList.add('is-view');
    });
  }

  if (document.querySelector('.js-modalDelveryClose')) {
    modalDeliveryClose.addEventListener('click', () => {
      bodyEle.classList.remove('is-fixed');
      modalDeliveryWindow.classList.remove('is-view');
    });
  }
}
/** end デリバリーメニュー */
