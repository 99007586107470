/** スクロール量に応じてheader背景のopacityを調整 */
export function scrollHeaderColorChange() {
  const scrollAllowMax = Number(300); // ページのどこまでをスクロール最大量とするか
  const headerBgEle = document.querySelector('.js-headerBg');
  const headerEle = document.getElementById('header');
  const scrollNum = Number(window.scrollY);
  const IS_TOP = 'is-top';
  const IS_SCROLLED = 'is-scrolled';

  if (headerEle.classList.contains(IS_TOP)) {
    if (scrollAllowMax < scrollNum) {
      headerEle.classList.add(IS_SCROLLED);
    } else {
      headerEle.classList.remove(IS_SCROLLED);
    }
  }
}
/** end スクロール量に応じてheader背景のopacityを調整 */

/** ハンバーガーメニュー開閉 */
export function hamburgerOpen() {
  const bodyEle = document.querySelector('body');
  const hamburgerTrigger = document.querySelector('.js-hamburgerTrigger');
  const headerSpMenu = document.querySelector('.js-headerSpMenu');
  const IS_ACTIVE = 'is-active';
  const IS_ACTIVED = 'is-actived';
  const IS_FIXED = 'is-fixed';
  const IS_VIEW = 'is-view';

  hamburgerTrigger.addEventListener('click', () => {
    if (hamburgerTrigger.classList.contains(IS_ACTIVE)) {
      hamburgerTrigger.classList.remove(IS_ACTIVE);
      hamburgerTrigger.classList.add(IS_ACTIVED);
      bodyEle.classList.remove(IS_FIXED);
      headerSpMenu.classList.remove(IS_VIEW);
    } else {
      hamburgerTrigger.classList.remove(IS_ACTIVED);
      hamburgerTrigger.classList.add(IS_ACTIVE);
      bodyEle.classList.add(IS_FIXED);
      headerSpMenu.classList.add(IS_VIEW);
    }
  });
}
/** end ハンバーガーメニュー開閉 */

/** ハンバーガーメニュー内アコーディオン開閉 */
export function hamburgerInAcMenuOpen() {
  const hamburgerInAcTrigger = document.querySelectorAll('.js-hamburgerInAcTrigger');
  const IS_ACTIVE = 'is-active';
  const IS_ACTIVED = 'is-actived';

  hamburgerInAcTrigger.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      if (trigger.classList.contains(IS_ACTIVE)) {
        trigger.classList.remove(IS_ACTIVE);
        trigger.classList.add(IS_ACTIVED);
      } else {
        for (let i = 0; i < hamburgerInAcTrigger.length; i++) {
          hamburgerInAcTrigger[i].classList.remove(IS_ACTIVE);
          hamburgerInAcTrigger[i].classList.add(IS_ACTIVED);
        }
        trigger.classList.remove(IS_ACTIVED);
        trigger.classList.add(IS_ACTIVE);
      }
    });
  });
}
/** end ハンバーガーメニュー内アコーディオン開閉 */
